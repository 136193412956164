import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { getData } from "@/api/user";
import router from '../router'

import { setTokens,getTokens } from "@/libs/util";

export default createStore({
    state: {
        ali_oss: 'https://ebaina.oss-cn-hangzhou.aliyuncs.com/',
        wr_base_url: 'http://view.officeapps.live.com/op/view.aspx?src=', // 微软文档预览 官方地址
        userInfo: {},
        userName: '',
        userId: '',
        avatarImgPath: '',
        token: getTokens(),
        login_box: false,
        message_count: 0,
        shopping_car_count: 0 ,
        ebaina_ebc_url: '',
        ebaina_url: '',
        ebaina_charge_url:'',
        wd:0,
        detail_img: [],
        smt_info:{}

    },
    mutations: {
        setLoginBox(state, login_box) {
            state.login_box = login_box;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setAvatar(state, avatarPath) {
            state.avatarImgPath = avatarPath
        },
        setUserId(state, id) {
            state.userId = id
        },
        setUserName(state, name) {
            state.userName = name
        },
        setToken(state, token) {
            state.token = token;
            setTokens(token);
        },
        setCount(state) {
            if(state.userId) {
                getData({
                    url: `api/my_home/my_base`,
                    data: {},
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        state.shopping_car_count = data.data.shopping_car_count
                        state.message_count = data.data.xincheng_notification_count
                    }
                })
            }else {
                state.message_count = 0
                state.shopping_car_count = 0
            }
        },
        setCarCount(state, count) {
            state.shopping_car_count = count
        },
        setUrl(state,urls) {
            state.ebaina_ebc_url = urls.ebaina_ebc_url
            state.ebaina_url = urls.ebaina_url
            state.ebaina_charge_url = urls.ebaina_charge_url
        },
        setWd(state,wd) {
            state.wd = wd
        },
        setDetailImg(state, img) {
            state.detail_img = img
        },
        setSmtInfo(state, info) {
          state.smt_info = info
        }
    },
    actions: {
        // 登录
        handleLogin({ commit }, info) {
            commit("setToken", info.jwt_token);
            commit("setUserInfo", info.user_info);
            commit("setUserId", info.user_info.id);
            commit("setUserName", info.user_info.nickname);
            commit("setAvatar", info.user_info.avatar);
            commit("setCount")
            window.location.reload();
        },
        // 登录
        handleQQLogin({ commit }, info) {
            commit("setToken", info.jwt_token);
            commit("setUserInfo", info.user_info);
            commit("setUserId", info.user_info.id);
            commit("setUserName", info.user_info.nickname);
            commit("setAvatar", info.user_info.avatar);
            commit("setCount")
            router.replace({path:'/'})
            window.scrollTo(100, 0)
        },
        // 退出登录
        handleLogOut({ commit }) {
            commit("setToken", "");
            commit("setUserInfo", {});
            commit("setUserId", '');
            commit("setUserName", '');
            commit("setAvatar", '');
            commit("setCount")
            router.replace({ path: '/' })
            window.scrollTo(100, 0)
        },
    },
    modules: {},
    plugins: [createPersistedState()],
});
